<template>
    <div>
        <ek-dialog
            v-if="
                getUserRoles() == Admin ||
                getUserRoles() == SuperAdmin ||
                getUserRoles() == DataEntry
            "
            ref="addDialog"
            title="إضافة درس"
            size="md"
            btnText="اضافة درس"
            @ok="submit()"
            @close="reset"
        >
            <template #body>
                <ValidationObserver ref="lessonForm">
                    <b-form>
                        <div
                            class="d-flex justify-content-start align-items-center"
                        >
                            <div
                                class="d-flex justify-content-center align-items-center"
                            >
                                <b-form-group
                                    label="محتوى درس"
                                    v-slot="{ lessonType }"
                                >
                                    <b-form-radio-group
                                        id="radio-group-2"
                                        v-model="lessonDto.lessonType"
                                        :aria-describedby="lessonType"
                                        name="radio-component"
                                    >
                                        <b-form-radio :value="'Document'"
                                            >مستندات</b-form-radio
                                        >
                                        <b-form-radio :value="'Video'"
                                            >فيديوهات</b-form-radio
                                        >
                                    </b-form-radio-group>
                                </b-form-group>
                                <b-form-group
                                    label="حالة درس"
                                    v-slot="{ lessonStatus }"
                                >
                                    <b-form-radio-group
                                        id="radio-group-2"
                                        v-model="lessonDto.isFree"
                                        :aria-describedby="lessonStatus"
                                        name="radio-sub-component"
                                    >
                                        <b-form-radio :value="true"
                                            >مجاني</b-form-radio
                                        >
                                        <b-form-radio :value="false"
                                            >مدفوع</b-form-radio
                                        >
                                    </b-form-radio-group>
                                </b-form-group>
                            </div>
                        </div>
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: '  اسم الدرس مطلوب',
                                },
                            ]"
                            name="name"
                            placeholder=" ادخل اسم الدرس"
                            label="  اسم الدرس"
                            v-model="lessonDto.name"
                        >
                        </ek-input-text>
                        <!-- جامعة كلية فرع  -->

                        <!-- <pre>{{ lessonDto }}</pre> -->
                        <ek-input-select
                            name="الفرع"
                            label=" تابع ل جامعة - كلية - فرع "
                            placeholder="جامعة - كلية - فرع"
                            :options="departmentsSubjectsOptions"
                            v-model="lessonDto.departmentId"
                            @change="resetCourse"
                        >
                        </ek-input-select>
                        <ek-input-select
                            name="المادة"
                            v-model="lessonDto.subjectId"
                            label=" تابع ل سنة - فصل -المادة "
                            placeholder="تابع ل سنة - فصل -المادة "
                            v-if="lessonDto.departmentId"
                            :options="
                                departmentsSubjectsOptions.find(
                                    (dep) => dep.id == lessonDto.departmentId
                                ).subjects
                            "
                            @change="resetCourseList"
                        >
                        </ek-input-select>

                        <ek-input-text
                            v-else
                            name="name"
                            label=" تابع ل سنة - فصل -المادة "
                            placeholder="تابع ل سنة - فصل -المادة "
                            v-model="emptyCourse"
                        >
                        </ek-input-text>

                        <ek-input-select
                            v-if="lessonDto.subjectId"
                            v-model="lessonDto.courseId"
                            :options="lessonDto.courseList"
                            label=" كورس "
                            placeholder="كورس"
                        >
                        </ek-input-select>
                        <ek-input-textarea
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'وصف الدرس مطلوب',
                                },
                            ]"
                            name="name"
                            placeholder="وصف الدرس"
                            v-model="lessonDto.description"
                            label="وصف الدرس"
                        >
                        </ek-input-textarea>
                        <!-- {{ thumbUrl }} -->
                        <div v-if="lessonDto.courseId">
                            <ek-input-image
                                ref="imgThumbFile"
                                label=" غلاف الدرس "
                                name="صور "
                                @input="storeCoverLessonFile"
                            >
                                <h5>انقر لتحميل غلاف</h5>
                            </ek-input-image>
                            <!-- {{ url }} -->
                            <ek-input-image
                                class="mt-1"
                                ref="imgFile"
                                label=" الملف الدرس "
                                name="الملف "
                                @input="storeLessonFile"
                            >
                                <h5>انقر لتحميل الملف</h5>
                            </ek-input-image>
                            <b-button
                                v-if="!filesUploaded"
                                class="mt-2 w-100"
                                variant="success"
                                @click="uploadLessonFile"
                                bolock
                                >رفع الملفات
                            </b-button>
                            <template v-if="isLoading">
                                <div class="p-2">
                                    <span class="text-primary"
                                        >جاري رفع الملف
                                    </span>
                                    <b-progress
                                        class="mt-1"
                                        :value="uploadProgress"
                                        :max="100"
                                        show-progress
                                        animated
                                    >
                                    </b-progress>
                                </div>
                            </template>
                            <p v-if="!filesUploaded" class="my-1 text-warning">
                                يجب رفع الملفات اولا حتى تستطيع حفظ الدرس
                            </p>
                        </div>

                        <div v-else class="mt-3 d-flex justify-content-center">
                            <h4>اختر كورس مناسب لاضافة ملف</h4>
                        </div>
                    </b-form>
                </ValidationObserver>
            </template>
        </ek-dialog>
    </div>
</template>
<script>
import { BToast } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions } from "vuex";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin, DataEntry } from "@/router";
import { toast } from "@/libs/vue-toast.js";

export default {
    components: {
        ValidationObserver,
    },
    data() {
        return {
            // departmentId: "",

            extensionOfFile: "",
            isLoading: false,
            emptyCourse: "لا يوجد كورسات لهذا الفرع",
            fileId: "",
            file: {
                file: "",
                thumbFile: "",
            },
            filesUploaded: false,
            SuperAdmin,
            Admin,
            DataEntry,
        };
    },
    computed: {
        ...mapState({
            lessonDto: ({ lessons }) => lessons.lessonDto,
            // departmentId: ({ lessons }) => lessons.departmentId,
            url: ({ lessons }) => lessons.url,
            uploadProgress: ({ lessons }) => lessons.uploadProgress,
            thumbUrl: ({ lessons }) => lessons.thumbUrl,
            departmentsSubjectsOptions: ({ courses }) =>
                courses.departmentsSubjectsOptions,
            universityNameCourse: ({ university }) =>
                university.universityNameCourse,
        }),
    },
    methods: {
        ...mapActions([
            "getCourseList",
            "lessonForm",
            "getNamesCourses",
            "getDepartmentsWithSubjects",
            "addLesson",
            "addFileLesson",
            "addCoverFileLesson",
            "getLessons",
            "getCourseBySubjectId",
        ]),
        getUserRoles,
        reset() {
            this.$refs.lessonForm.reset();
            if (this.$refs.imgFile) this.$refs.imgFile.reset();
            if (this.$refs.imgThumbFile) this.$refs.imgThumbFile.reset();
            this.$refs.addDialog.close();
            this.filesUploaded = false;
        },
        resetCourse() {
            this.lessonDto.courseId = "";
            this.lessonDto.subjectId = "";
        },
        resetCourseList() {
            this.lessonDto.courseId = "";
        },
        storeLessonFile(event) {
            this.file.file = event.file;
            let extensions = event.file.type.split("/");
            console.log(extensions);
            if (extensions[1] == "pdf") {
                this.extensionOfFile = "Document";
                console.log(this.extensionOfFile);
            } else if (extensions[1] == "mp4") {
                this.extensionOfFile = "Video";
                console.log(this.extensionOfFile);
            }
        },
        storeCoverLessonFile(event) {
            this.file.thumbFile = event.file;
        },
        uploadLessonFile() {
            this.isLoading = true;
            if (
                this.file.file &&
                this.file.thumbFile &&
                this.extensionOfFile == this.lessonDto.lessonType
            ) {
                this.addFileLesson({
                    file: this.file.file,
                    thumbFile: this.file.thumbFile,
                    courseId: this.lessonDto.courseId,
                })
                    .then((id) => {
                        this.fileId = id;
                        this.filesUploaded = 1 === 1;
                        this.isLoading = false;
                    })
                    .catch((er) => {
                        const toast = new BToast();

                        toast.$bvToast.toast(er.response.data, {
                            title: "فشل رفع الملف",
                            variant: "error",
                            autoHideDelay: 5000,
                        });
                    });
            } else if (!this.file.file || !this.file.thumbFile) {
                const toast = new BToast();
                toast.$bvToast.toast("يجب اختيار ملف و غلاف ", {
                    variant: "warning",
                    toaster: "b-toaster-bottom-right",
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                });
            } else if (this.extensionOfFile !== this.lessonDto.lessonType) {
                const bToast = new BToast();
                bToast.$bvToast.toast("يوجد خطأ باختيار محتوى درس", {
                    title: "يجب اختيار محتوى درس مناسب",
                    variant: "warning",
                    toaster: "b-toaster-bottom-right",
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                });
            }
        },
        submit() {
            this.$refs["lessonForm"].validate().then((success) => {
                if (success && this.filesUploaded) {
                    this.addLesson({
                        ...this.lessonDto,
                        fileId: this.fileId,
                    })
                        .then((res) => {
                            this.$store.commit("Add_Lessons_Courses", res.data);
                            this.getLessons({
                                lessonType: "",
                                pageIndex: 1,
                                pageSize: 10,
                            });
                            if (res.status == 200) {
                                toast.open({
                                    message: "تم اضاف الدرس بنجاح",
                                    type: "success",
                                    duration: 2000,
                                    dismissible: true,
                                });
                            }
                            this.$refs.addDialog.close();
                            this.$store.commit("Reset_Lesson_Dto");
                            this.$emit("fillSelectLesson");
                        })
                        .catch((error) => {
                            if (error.response.status == 400) {
                                toast.open({
                                    message: error.response.data,
                                    type: "error",
                                    duration: 2000,
                                    dismissible: true,
                                });
                            }
                        });
                }
            });
        },
    },

    watch: {
        "lessonDto.subjectId": {
            handler(newCourse) {
                // Your logic when the 'course' property in 'lessonDto' changes
                if (newCourse) {
                    this.getCourseBySubjectId(newCourse).then(() => {
                        console.log(this.lessonDto.courseList);

                        return this.lessonDto.courseList;
                    });
                }
            },
        },
        file: {
            handler: function (newValue, oldValue) {
                console.log("newValue", newValue);
                console.log("oldValue", oldValue);
                this.filesUploaded = false;
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.getNamesCourses();
        this.getDepartmentsWithSubjects();
        this.getUserRoles();
    },
};
</script>
